import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';

const links = [
  { text: 'About Us', href: '#about-us' },
  { text: 'Our Offerings', href: '#offerings' },
  { text: 'Our Partners', href: '#partners' },
  { text: 'Customer Portfolio', href: '#customers' },
  { text: 'Contact Us', href: '#contact-us' },
];

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  return (
      <nav className="bg-white shadow-lg sticky top-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <AnchorLink className="flex items-center text-2xl" href="#hero">
                  <div className="w-12 mr-3">
                    <LogoIcon></LogoIcon>
                  </div>
                  <p class="text-primary">Sigar</p>
                  <p class="mx-1 text-primary-lighter">Indonesia</p>
                </AnchorLink>
              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  {links.map((link) => (
                    <AnchorLink
                      className="text-sigarTeal hover:bg-primary-lighter hover:text-white px-3 py-2 rounded-md text-md font-medium"
                      href={link.href}
                    >
                      {link.text}
                    </AnchorLink>
                  ))}
                </div>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-sigarTeal inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-sigarTeal focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {links.map((link) => (
                    <AnchorLink
                      className="text-sigarTeal hover:bg-primary-lighter hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                      href={link.href}
                    >
                      {link.text}
                    </AnchorLink>
                  ))}
                
              </div>
            </div>
          )}
        </Transition>
      </nav>
  );
}

export default Header;
