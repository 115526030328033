import React from 'react';
import Footer from './Footer';
import Header from './Header';
import { Helmet } from 'react-helmet';
import favicon from '../../images/favicon.ico'

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sigar Indonesia - PT Sigar IT Cloud Services</title>
        <link rel="canonical" href="https://sigarindonesia.com" />
        <link rel="icon" href={favicon} />
      </Helmet>
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
